<template>
	<div>
		<q-dialog v-model="prompt" @hide="closePrompt">
			<q-card style="width: 700px; max-width: 80vw;">
				<q-card-section>
					<div v-if="settings.type == 'add'" class="text-h6">Add on call period</div>
					<div v-else class="text-h6">Edit on call period</div>
				</q-card-section>
				<q-card-section class="q-py-none">
					<date-time-picker
						class="q-pa-none q-mb-md"
						:hint="'Select date and time. Format DD/MM/YYYY HH:MM:SS'"
						:label="'Start of On Call Period'"
						:min_date="min_date"
						@selected="startDateSelected"
						:default_year_month="min_date"
						:error="start_date_error"
						:existing_datetime="start_date"
					/>
					<date-time-picker
						class="q-pa-none q-mb-md"
						:hint="'Select date and time. Format DD/MM/YYYY HH:MM:SS'"
						:label="'End of On Call Period'"
						:disable="!start_date"
						:min_date="min_date_for_end_date"
						:default_year_month="min_date_for_end_date"
						@selected="endDateSelected"
						:error="end_date_error"
						:existing_datetime="end_date"
					/>
				</q-card-section>
				<q-card-section>
					<div>Time zone settings:</div>
					<time-zone-picker
						class="q-pa-none"
						@timezoneSelected='timezoneSelected'
					/>
				</q-card-section>
				<q-card-actions align="right" class="text-primary">
					<q-btn flat label="Cancel" @click="closePrompt" />
					<q-btn
						flat
						:label="settings.type=='add' ? 'Add Period' : 'Edit Period'"
						@click="btnConfirm"/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>

import DateTimePicker from '@/components/utils/dialogs/DateTimePicker.vue'
import TimeZonePicker from '@/components/utils/dropdowns/TimeZonePicker.vue'
import DefaultTimeZoneSetting from '@/components/datasets/edit/settings/DefaultTimeZoneSetting.vue'



export default {
	name: 'AddOnCallPeriod',
	components: { DateTimePicker, TimeZonePicker, DefaultTimeZoneSetting },
	props: {
		settings: Object
	},
	data() {
		return {
			prompt: true,
			start_date: null,
			end_date: null,
			timezone: 'Europe/London',
			start_date_error: false,
			end_date_error: false,
			min_date: new Date().toLocaleDateString('en-UK')
		}
	},
	computed: {
		min_date_for_end_date(){
			return this.start_date ? this.start_date.split(" ")[0] : ""
		},
	},
	methods: {
		closePrompt(){
			this.$emit('closePrompt')
		},
		startDateSelected(date){
			this.start_date = date
			if(date){
				this.start_date_error = false
			}
		},
		endDateSelected(date){
			this.end_date = date
			if(date){
				this.end_date_error = false
			}
		},
		timezoneSelected(timezone){
			this.timezone = timezone
		},
		btnConfirm(){
			this.start_date_error = this.start_date ? false : true
			this.end_date_error = this.end_date ? false : true
			if (!this.start_date_error && !this.end_date_error){
				let dates = this.start_date.split(' ')[0].split('/')
				let time = this.start_date.split(' ')[1]
				let date_start = `${dates[2]}/${dates[1]}/${dates[0]}T${time}`.replaceAll('/', '-')

				dates = this.end_date.split(' ')[0].split('/')
				time = this.end_date.split(' ')[1]
				let date_end = `${dates[2]}/${dates[1]}/${dates[0]}T${time}`.replaceAll('/', '-')
				let data = {
					date_start,
					date_end,
					timezone: this.timezone
				}
				if (this.settings.type == 'add'){
					this.$emit('createOnCall', data)
				} else {
					data['on_call_period_id'] = this.settings.on_call_period_id
					this.$emit('editOnCall', data)
				}
			}
		},
	},
	created(){
		if (this.settings.type == 'edit'){
			this.start_date = this.settings.start_date;
			this.end_date = this.settings.end_date;
		}
	}
}
</script>
