<template>
	<div>
		<q-dialog v-model="add_item_dialog" transition-show="scale" transition-hide="scale" @hide="$emit('closeDialog')">
			<q-card style="width: 300px">
				<q-card-section class="bg-primary text-white">
					<div class="text-h6">Assign to user:</div>
				</q-card-section>
				<q-card-section class="q-pt-none q-mt-md">
					<date-time-picker
						:hint="'Select date and time. Format DD/MM/YYYY HH:MM:SS'"
						:label="'Start:'"
						:min_date="min_date"
						:max_date="max_date"
						:default_year_month="min_date"
						:error="start_date_error"
						:existing_datetime="start_date"
						:disable="selected_dates.start_date_disabled"
						@selected="startDateSelected"
					/>
					<date-time-picker
						:hint="'Select date and time. Format DD/MM/YYYY HH:MM:SS'"
						:label="'End:'"
						:min_date="min_date_for_end_date"
						:max_date="max_date"
						:max_time="max_time"
						:default_year_month="min_date_for_end_date"
						:error="end_date_error"
						:existing_datetime="end_date"
						:disable="selected_dates.end_date_disabled"
						@selected="endDateSelected"
					/>
					<q-select
						v-model="selected_user"
						:options="available_users"
						:label="'Assign period to:'"
						:hint="'Select 1 option'"
						:error="selected_user_error"
						@input="selected_user_error = false"
					/>
				</q-card-section>
				<q-card-actions align="right" class="bg-white text-primary">
					<q-btn flat label="Cancel" @click="$emit('closeDialog')" />
					<q-btn flat label="OK" @click="addItem"/>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>

import DateTimePicker from '@/components/utils/dialogs/DateTimePicker.vue'
import TimeZonePicker from '@/components/utils/dropdowns/TimeZonePicker.vue'
import DefaultTimeZoneSetting from '@/components/datasets/edit/settings/DefaultTimeZoneSetting.vue'
import UserAPI from '@/services/api/Users.js'



export default {
	name: 'AssignOnCallDialog',
	components: { DateTimePicker, TimeZonePicker, DefaultTimeZoneSetting, UserAPI },
	props: {
		on_call_period: Object,
		selected_dates: Object,
		available_users: Array,
	},
	data() {
		return {
			start_date: this.selected_dates.start_date,
			end_date: this.selected_dates.end_date,
			selected_user: null,
			add_item_dialog: false,
			start_date_error: false,
			end_date_error: false,
			selected_user_error: false,
			min_date: this.on_call_period.periods[0].start_date.split(' ')[0],
			max_date: this.on_call_period.periods[0].end_date.split(' ')[0]
		}
	},
	computed: {
		min_date_for_end_date(){
			return this.start_date ? this.start_date.split(" ")[0] : ""
		},
		max_time(){
			return this.on_call_period.periods[0].end_date.split(' ')[1]
		}
	},
	methods: {
		addItem(){
			if (!this.start_date){this.start_date_error = true}
			if (!this.end_date){this.end_date_error = true}
			if (!this.selected_user){this.selected_user_error = true}
			if (!this.start_date || !this.end_date || !this.selected_user){return}

			this.$emit('addItem', {
				start_date: this.start_date,
				end_date: this.end_date,
				user: {address_string: this.selected_user.address_string, id: this.selected_user.id}
			})
		},
		closePrompt(){
			this.$emit('closePrompt')
		},
		startDateSelected(date){
			this.start_date = date
			if(date){
				this.start_date_error = false
			}
		},
		endDateSelected(date){
			this.end_date = date
			if(date){
				this.end_date_error = false
			}
		},
	},
	created(){
		if (this.available_users.length){
			this.add_item_dialog = true
		}
	},
}
</script>
