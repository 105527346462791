<template>
	<q-item>
		<q-item-section class="full-width">
			<q-input
				outlined
				v-model="selected_datetime"
				:hint="hint"
				:label="label"
				@click="date_time_dialog=true"
				:disable='disable'
				:error='error'
				:error-message="'This field is required'"
			>
				<template v-slot:append>
					<q-btn
						flat
						icon="calendar_today"
						class="cursor-pointer date-btn"
						@click="date_time_dialog=true"
					/>
				</template>
			</q-input>
			<q-dialog v-model="date_time_dialog">
				<div
					class="q-pa-md date-time-container"
					:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
					:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
				>
					<div class="q-gutter-md row items-start date-time">
						<q-date
							v-if="!hide_date"
							v-model="selected_date"
							mask="DD/MM/YYYY"
							color="primary"
							:default-year-month="default_year_month_formatted"
							:options="allowedDays"
						/>
						<q-time
							v-if="!hide_time"
							format24h
							v-model="selected_time"
							mask="HH:mm:ss"
							color="primary"
							:options="allowedTime"
						/>
					</div>
					<div class="q-mt-md flex justify-end full-width">
						<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
					</div>
				</div>
			</q-dialog>
		</q-item-section>
	</q-item>
</template>
<script>

export default {
	name: 'DateTimePicker',
	props: {
		label: String,
		hint: String,
		min_date: String,
		max_date: String,
		min_time: String,
		max_time: String,
		hide_date: Boolean,
		hide_time: Boolean,
		disable: Boolean,
		error: Boolean,
		existing_datetime: String,
		default_year_month: String
	},
	data(){
		return {
			selected_date: "",
			selected_time: "",
			date_time_dialog: false,
			enable_max_time: false,
			default_year_month_formatted: ""
		}
	},
	computed: {
		selected_datetime(){
			let selected_datetime = ""
			if (this.selected_date && this.selected_time){
				selected_datetime = this.selected_date + " " + this.selected_time
			}
			if (this.selected_date == this.max_date){
				this.enable_max_time = true
			}
			this.$emit('selected', selected_datetime)
			return selected_datetime
		}
	},
	methods: {
		formatDefaultYearMonth(){
			let year = this.default_year_month.split('/')[2]
			let month = this.default_year_month.split('/')[1]

			return year + "/" + month
		},
		allowedDays(date){
			/**
			 	* Iterate through each date of the month
				* @param  {String} date YYYY/MM/DD.
				* @return {Boolean} Properly formatted initials.
				* The date we compare with is in different format DD/MM/YYYY
				* Change the format of date, to make it comparable
			*/

			let condition = true
			if (this.min_date){
				let min_date = `${this.min_date.split('/')[2]}/${this.min_date.split('/')[1]}/${this.min_date.split('/')[0]}`
				condition = condition && (date >= min_date)
			}
			if (this.max_date){
				let max_date = `${this.max_date.split('/')[2]}/${this.max_date.split('/')[1]}/${this.max_date.split('/')[0]}`
				condition = condition && (date <= max_date)
			}
			return condition
		},
		allowedTime(hr, min, sec){
			if (!this.enable_max_time){
				return true
			}
			if (this.min_time){
				// NOTE: Not implemented !
				// if (hr < parseInt(split_time[0])) {return false}
				// if (min < parseInt(split_time[1])) {return false}
				// if (sec < parseInt(split_time[2])) {return false}
			}
			if (this.max_time){
				let split_time = this.max_time.split(':')
				if (hr > parseInt(split_time[0])) {return false}
				if (hr == parseInt(split_time[0]) && min > parseInt(split_time[1])) {return false}
				if (sec > parseInt(split_time[2])) {return false}
			}
			return true
		}
	},
	created(){
		if (this.existing_datetime){
			this.selected_date = this.existing_datetime.split(' ')[0]
			this.selected_time = this.existing_datetime.split(' ')[1]
		}
		this.default_year_month_formatted = this.formatDefaultYearMonth()
	},
	watch: {
		default_year_month: function(sm){
			this.default_year_month_formatted = this.formatDefaultYearMonth()
		}
	}
}
</script>


<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>
<style lang="scss">
	.date-btn .q-btn__wrapper {
		padding: 0.5rem;
	}
</style>