<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			On call settings for {{user_instance.address_string}}
		</h1>
		<div style="padding-bottom: 1rem">
			<q-table
				title="Weeks"
				:data="rows"
				:columns="headers"
				row-key="name"
				:pagination.sync="pagination"
				style="width: 100%"
			>
				<template v-slot:top>
					<q-btn color="primary" label="Add On Call Period" @click="addOnCallClicked" />
				</template>
				<template v-slot:body-cell-buttons='item'>
					<q-td>
						<div class='material-icons-outlined'>
							<q-btn color='dark' icon="o_assignment" @click="assignBtnClicked(item.rowIndex)" round flat>
								<q-tooltip class="bg-indigo">
									Assign
								</q-tooltip>
							</q-btn>
							<q-btn color='dark' icon="o_edit" @click="editBtnClicked(item.rowIndex)" round flat>
								<q-tooltip class="bg-indigo">
									Edit
								</q-tooltip>
							</q-btn>
							<q-btn color='dark' icon="o_delete" @click="deleteBtnClicked(item)" round flat>
								<q-tooltip class="bg-indigo">
									Delete
								</q-tooltip>
							</q-btn>
						</div>
					</q-td>
				</template>
				<template v-slot:pagination>
					<span class="row items-center justify-end">
						<q-pagination
							v-model="pagination.page"
							boundary-links
							direction-links
							:max="pagesNumber"
							:max-pages="5"
							size="md"
							color="grey-8"
							active-color="primary"
						/>
					</span>
				</template>
			</q-table>

			<add-on-call-period
				v-if="prompt"
				:settings="prompt_settings"
				@closePrompt="closePrompt"
				@createOnCall="onCallAddOrEdit($event, 'add')"
				@editOnCall="onCallAddOrEdit($event, 'edit')"
			/>
			<assign-on-call-period
				v-if="assign_prompt"
				:on_call_period=" rows[assign_prompt_index]"
				@closePrompt="closePrompt"
				@assignPeriod="assignPeriod"

			/>
		</div>
		<div
			style="
				position: sticky;
				bottom: 20px;
				width: fit-content;
				margin: 10px auto;
			"
		>
			<q-btn
				v-if="$q.screen.gt.sm"
				rounded
				icon="download"
				padding="md lg"
				label="Export"
				color="primary"
				@click="exportOnCall"
			/>
			<q-btn
				v-else
				round
				icon="download"
				size="lg"
				color="primary"
				@click="exportOnCall"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Export
				</q-tooltip>
			</q-btn>
		</div>
	</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex"
import AddOnCallPeriod from '@/components/users/edit/data/AddOnCallPeriod.vue'
import AssignOnCallPeriod from '@/components/users/edit/data/AssignOnCallPeriod.vue'
import moment from 'moment-timezone'

export default {
	name: 'OnCall',
	components: { AddOnCallPeriod, AssignOnCallPeriod },
	data() {
		return {
			headers: [],
			rows: [],
			pagination: {
				page: 1,
				rowsPerPage: 10,
			},
			prompt: false,
			prompt_settings: {},
			assign_prompt: false,
			assign_prompt_index: null
		}
	},
	computed: {
		...mapGetters(['user_on_call_settings', 'user_instance', 'logged_user']),
		pagesNumber () {
			if (this.pagination.rowsPerPage != 0){
				return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
			}else{
				return 1
			}
		},
	},
	methods: {
		...mapActions([
			'deleteUserOnCallSettings',
			'getUserOnCallSettings',
			'createUserOnCallSettings',
			'editUserOnCallSettings',
			'exportOnCallSettings'
		]),
		async assignPeriod(){
			this.closePrompt()
			await this.getUserOnCallSettings(this.user_instance.id)
			this.rows = this.user_on_call_settings.weeks
		},
		assignBtnClicked(index){
			this.assign_prompt = true
			this.assign_prompt_index = index
		},
		editBtnClicked(index){
			this.prompt_settings['type'] = 'edit'
			this.prompt_settings['start_date'] = this.rows[index].periods[0].start_date
			this.prompt_settings['end_date'] = this.rows[index].periods[0].end_date
			this.prompt_settings['on_call_period_id'] = this.rows[index].periods[0].id
			this.prompt = true
		},
		deleteBtnClicked(item){
			let start_date = item.row.start_date.split(' ')[0]
			let end_date = item.row.end_date.split(' ')[0]
			this.$q.dialog({
				message: `Are you sure you want to delete ${start_date} - ${end_date} On Call Period?`,
				cancel: true,
				ok: {
					label: "delete",
					flat: true
				},
				focus: 'none'
			}).onOk(async () => {
				const response = await this.deleteUserOnCallSettings(item.row.periods[0].id)
				if (response && response.error) {
					this.$q.notify({
						timeout: 6700,
						message: response.error_message,
						actions: [
							{
								label: 'Dismiss',
								color: 'primary',
							},
						],
					})
					return
				}
				this.$q.notify('On call deleted successfully.')
				await this.getUserOnCallSettings(this.user_instance.id)
				this.rows = this.user_on_call_settings.weeks
			})
		},
		addOnCallClicked(){
			this.prompt_settings['type'] = 'add'
			this.prompt = true
		},
		closePrompt(){
			this.prompt = false,
			this.assign_prompt = false
			this.assign_prompt_index = null
		},
		getDateStart(date_start, week_start){
			date_start = moment.tz(date_start, "DD/MM/YYYY HH:mm", "UTC");
			week_start = moment.tz(week_start, "DD/MM/YYYY HH:mm", "UTC");
			if (date_start < week_start){
				return week_start.format('DD/MM/YYYY (HH:mm)');
			}
			date_start = date_start.clone().tz(this.user_instance.preferred_timezone)
			return date_start.format('DD/MM/YYYY (HH:mm)');
		},
		getDateEnd(date_end, week_end){
			date_end = moment.tz(date_end, "DD/MM/YYYY HH:mm", "UTC");
			week_end = moment.tz(week_end, "DD/MM/YYYY HH:mm", "UTC");
			if (date_end > week_end){
				return week_end.format('DD/MM/YYYY (HH:mm)');
			}
			date_end = date_end.clone().tz(this.user_instance.preferred_timezone)
			return date_end.format('DD/MM/YYYY (HH:mm)');
		},
		async onCallAddOrEdit(data, type){
			this.closePrompt()
			data['user_id'] = this.user_instance.id
			let response = null
			if (type == 'add'){
				response = await this.createUserOnCallSettings(data)
			}
			else {
				response = await this.editUserOnCallSettings(data)
			}
			if (response && response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message.non_field_errors,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(`On call ${type == 'add' ? 'created' : 'updated'} successfully.`)
			await this.getUserOnCallSettings(this.user_instance.id)
			this.rows = this.user_on_call_settings.weeks
		},
		async exportOnCall(){
			let response = await this.exportOnCallSettings({
				user_periods: this.user_on_call_settings,
				user_id: this.user_instance.id
			})
			if (response && response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(`Your export has been scheduled. A link to the export file will be sent to ${this.logged_user.email}.`)
		}
	},
	created(){
		this.headers =[
			{name: "week_number", field: "week_number", label: "Week number", align: 'center'},
			{name: "start_date", field: obj => obj.start_date.split(' ')[0], label: "Start date", align: 'center'},
			{name: "end_date", field: obj => obj.end_date.split(' ')[0], label: "End date", align: 'center'},
			{
				name: "periods",
				field: obj => {
					let start_date = this.getDateStart(obj.periods[0].start_date, obj.start_date)
					let end_date= this.getDateEnd(obj.periods[0].end_date, obj.end_date)
					return `${start_date} - ${end_date}`
				},
				label: "Periods (UTC Time)",
				align: 'center'
			},
			{name: "buttons", field: "btns", label: "Actions", align: "center"}
		]
		this.rows = this.user_on_call_settings.weeks
	}
}
</script>
