<template>
	<div>
		<q-dialog v-model="assign_on_call_prompt" @hide="closePrompt">
			<q-card style="width: 700px; max-width: 80vw;">
				<q-card-section>
					<div class="text-h6">Assign on call period</div>
				</q-card-section>
				<q-card-section class="q-py-none">
					<div class="q-gutter-sm column">
						<q-radio
							v-model="is_entire_period"
							:val="true"
							label="Assign entire on call period to a single user"
						/>
						<q-radio
							v-model="is_entire_period"
							:val="false"
							label="Split on call period between multiple users"
						/>
					</div>
					<div v-if="items.length" class="q-pa-md" style="max-width: 350px">
						<q-list class="column">
							<div v-for="(item, index) in items" :key="index">
								<q-item>
									<q-item-section>
										<q-item-label>{{item.user.address_string}}</q-item-label>
										<q-item-label caption lines="2">
											<span>Start:&nbsp;</span>{{item.start_date.split(' ')[0]}}<br/>
											<span>Finish:&nbsp;</span>{{item.end_date.split(' ')[0]}}
										</q-item-label>
									</q-item-section>
								</q-item>
								<q-separator spaced inset />
							</div>
						</q-list>
					</div>
					<q-btn
						outline
						class="q-ml-md q-px-sm q-my-md"
						color="primary"
						label="Select User(s)"
						:disable="disable_add_button"
						@click="addButtonClicked"
					/>
				</q-card-section>
				<q-separator />
				<q-card-actions align="right" class="text-primary">
					<q-btn flat label="Cancel" @click="closePrompt" />
					<q-btn
						flat
						:label="'Assign Period'"
						@click="btnConfirm"/>
				</q-card-actions>
			</q-card>
		</q-dialog>
		<assign-on-call-dialog
			v-if="add_item_dialog"
			:on_call_period="on_call_period"
			:selected_dates="selected_dates"
			:available_users="available_users"
			@closeDialog="add_item_dialog=false"
			@addItem="addItem"
		/>
	</div>
</template>

<script>

import AssignOnCallDialog from '@/components/users/edit/data/AssignOnCallDialog'
import UserAPI from '@/services/api/Users.js'

export default {
	name: 'AssignOnCallPeriod',
	components: { AssignOnCallDialog, UserAPI },
	props: {
		on_call_period: Object,
	},
	data() {
		return {
			is_entire_period: true,
			items: [],
			disable_add_button: false,
			add_item_dialog: false,
			assign_on_call_prompt: true,
			selected_dates: {},
			available_users: [],
		}
	},
	computed: {
	},
	methods: {
		addButtonClicked(){
			this.add_item_dialog = true
			this.selectDates()
		},
		selectDates(){
			let dates = {
				start_date: "",
				start_date_disabled: false,
				end_date: "",
				end_date_disabled: false
			}
			if (this.is_entire_period){
				dates['start_date'] = this.on_call_period.periods[0].start_date
				dates['start_date_disabled'] = true
				dates['end_date'] = this.on_call_period.periods[0].end_date
				dates['end_date_disabled'] = true
			}
			else {
				if (this.items.length){
					dates['start_date'] = this.items[this.items.length -1].end_date
				} else {
					dates['start_date'] = this.on_call_period.periods[0].start_date
				}
				dates['start_date_disabled'] = true
			}
			this.selected_dates = dates
		},
		addItem(data){
			if (data.end_date == this.on_call_period.periods[0].end_date){
				this.disable_add_button = true
			}
			this.items.push({
				start_date: data.start_date,
				end_date: data.end_date,
				user: data.user
			})
			this.add_item_dialog = false
		},
		closePrompt(){
			this.$emit('closePrompt')
		},
		async btnConfirm(){
			if (!this.items.length){
				this.$q.notify({
					timeout: 6700,
					message: 'You have not selected any assignees.',
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			let data = {}
			if (this.is_entire_period){
				data = {
					assign_user_id: this.items[0].user.id,
					end_date: this.items[0].end_date,
					start_date: this.items[0].start_date,
					id: this.on_call_period.periods[0].id
				}
			} else{
				let periods_list = []
				let is_entire_period_assigned = false
				for (let item of this.items){
					if (item.end_date == this.on_call_period.periods[0].end_date){
						is_entire_period_assigned = true
					}
					periods_list.push({
						assign_user_id: item.user.id,
						end_date: item.end_date,
						start_date: item.start_date
					})
				}
				data = {
					assign_type: is_entire_period_assigned ? 'entire' : 'part',
					period_id: this.on_call_period.periods[0].id,
					periods_list: periods_list
				}
			}
			const response = await UserAPI.assignOnCallPeriod(data, this.is_entire_period)
			if (response && response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message.non_field_errors,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			} else{
				this.$q.notify('On Call Period assigned successfully.')
			}
			this.$emit('assignPeriod')
		},
		async checkAvailableUsers(){
			let response = await UserAPI.getAvailableUsersForAssigningOnCall(this.on_call_period.periods[0].id)
			if (response.error) {
				this.disable_add_button = true
				this.$q.notify({
					timeout: 6700,
					message: response.error_message.non_field_errors,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.available_users = response
		},
	},
	created(){
		this.checkAvailableUsers()
	},
	watch: {
		items: {
			deep: true,
			handler() {
				if(this.is_entire_period && this.items.length){
					this.disable_add_button = true
				}
			}
		},
	}
}
</script>
